<template>
  <div class="pages-list">

  </div>
</template>
<script>

export default {
  name: 'Pages',
  components: {},
  props: {
    page: Object
  },
  data() {
    return {
      blocks: [],
      blocksData: []
    }
  },
  methods: {
    addBlock(type) {
      switch (type) {
        case 'text':
          this.blocks.push({
            name: 'TextBlock',
            id: '_' + Math.random().toString(36).substr(2, 9),
            data: {
              content: {}
            },
          });
          break;
        case 'button':
          this.blocks.push({
            name: 'ButtonBlock',
            id: '_' + Math.random().toString(36).substr(2, 9),
            data: {
              content: {}
            },
          });
          break;
        case 'image':
          this.blocks.push({
            name: 'ImageBlock',
            id: '_' + Math.random().toString(36).substr(2, 9),
            data: {
              content: {}
            },
          });
          break;
        case 'video':
          this.blocks.push({
            name: 'VideoBlock',
            id: '_' + Math.random().toString(36).substr(2, 9),
            data: {
              content: {}
            },
          });
          break;
        case 'file':
          this.blocks.push({
            name: 'FileBlock',
            id: '_' + Math.random().toString(36).substr(2, 9),
            data: {
              content: {}
            },
          });
          break;
      }
    },
    savePage() {
      this.blocksData = [];

      this.blocks.forEach(block => {
        let bComponent = this.$refs.blockComponent.filter(b => {
          return b.blockData.id === block.id;
        });
        this.blocksData.push(bComponent[0].getData());
      });

      console.log(this.blocksData);

      //TODO: send data to API
    },
    deletePage() {
      console.log('delete page');
      //TODO: send delete request to API
    },
    deleteBlock(blockId) {
      this.blocks = this.blocks.filter((b) => {
        return b.id !== blockId
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/global";
  @import "../assets/scss/buttons";

  .page-builder {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px 0;


    .grid {
      display: grid;
      grid-template-columns: 1fr 100px;
      height: 100%;

      .page-content {
        padding: 50px;
      }

      .tools {
        position: sticky;
        top: 50px;
        background-color: $dark;
        padding: 10px 0 0;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%232b2b2b' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");

        button {
          display: block;
          width: 100%;
          font-size: 0.8rem;

          svg {
            font-size: 1.0rem;
            margin-bottom: 5px;
          }
        }

        h4 {
          color: #fff;
          margin: 0 0 5px;
        }

        hr {
          margin-bottom: 10px;
          border: none;
        }

        .save-btn {
          background-color: $alt;
          &:hover {
            background-color: darken($alt, 10%);
          }
        }

        .delete-btn {
          background-color: $danger;
          &:hover {
            background-color: darken($danger, 10%);
          }
        }
      }
    }
  }
</style>
