<template>
  <div class="pages">
    <h1>Criar nova página</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <div class="wrapper">
      <div>
        <label for="title">Título da Página</label>
        <input type="text" id="title" v-model="page.title" placeholder="Título da Página">
      </div>
      <div>
        <label for="url">URL amigável da página</label>
        <input type="text" id="url" v-model="page.url" v-on:keyup="formatURL" placeholder="URL amigável ex: pagina-de-exemplo">
      </div>
      <div>
        <label for="parent">Página pai</label>
        <select id="parent" v-model="page.parent">
          <option value="0">Esta é uma página pai</option>
          <option v-for="p in parentPages" :key="p.id" :value="p.id">{{ p.title }}</option>
        </select>
      </div>
      <div>
        <label for="sort">Ordenação da página</label>
        <input type="number" min="1" id="sort" v-model="page.sort" placeholder="Ordem crescente de prioridade (1 aparece antes de 2, por exemplo)">
      </div>
      <br>
      <button class="btn save-btn" v-on:click="savePage" :disabled="loading">{{ loading ? 'Salvando...' : 'Salvar Página' }}</button>
    </div>
    <Pages/>
  </div>
</template>

<script>
// @ is an alias to /src
import Pages from '@/components/Pages.vue'
const axios = require('axios').default;

export default {
  name: 'home',
  components: {
    Pages
  },
  data() {
    return {
      loading: false,
      pages: [],
      page: {
        title: 'Título da Página',
        url: 'url-da-pagina',
        sort: '',
        parent: 0
      }
    }
  },
  methods: {
    savePage() {
      let error = false;
      if (this.page.title === '') {
        error = true;
        this.$alert('Campo Título precisa ser preenchido', 'Ooops', 'warning');
      }
      if (this.page.url === '') {
        error = true;
        this.$alert('Campo URL precisa ser preenchido', 'Ooops', 'warning');
      }
      if (this.page.sort === '') {
        this.page.sort = 1;
      }

      if (!error) {
        this.loading = true;
        let self = this;

        axios.post(this.$apiUrl + 'pages/create', this.page)
        .then(response => {
          self.loading = false;
          this.$alert(response.data.message, 'Sucesso', 'success').then(() => {
            self.$router.push('/paginas/editar/' + response.data.pageId);
          });
        })
        .catch(error => {
          this.$alert(error.response.data.message, 'Erro', 'error');
          console.log(error.response.data.error);
          self.loading = false;
        });
      }
    },
    formatURL() {
      this.page.url = this.page.url.replace(/\s+/g, '-').toLowerCase();
    }
  },
  computed: {
    parentPages() {
      return this.pages.filter(p => {
        return p.parent === 0;
      })
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiUrl + 'pages/list')
            .then(response => {
              self.pages = response.data.pages;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }
  }
</style>
